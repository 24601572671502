<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <!-- <b-form-input
                 v-model="searchKey"
                 class="d-inline-block mr-1"
                 placeholder="搜索关键字..."
               />-->
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-form
            @submit.prevent="searchByCondition"
            @reset.prevent="resetCondition"
        >
          <b-card
              v-show="advanced_search_modal"
          >
            <b-row>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="调拨类型:"
                    label-for="allot_type"
                    label-size="sm"
                >
                  <v-select
                      id="allot_type"
                      :options="getCodeOptions('allot_type')"
                      :clearable="true"
                      v-model = "condition.allot_type"
                      class="select-size-sm"
                      placeholder="选择调拨类型"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="调出仓库:"
                    label-for="fromwarehouse_name"
                    label-size="sm"
                >
                  <b-form-input
                      id="fromwarehouse_name"
                      size="sm"
                      v-model="fromwarehousename"
                      @click="showSingleModal('调出仓库')"
                      readonly
                      placeholder="点击搜索调出仓库"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="调入仓库:"
                    label-for="towarehouse_name"
                    label-size="sm"
                >
                  <b-form-input
                      id="towarehouse_name"
                      size="sm"
                      v-model="towarehousename"
                      @click="showSingleModal('调入仓库')"
                      readonly
                      placeholder="点击搜索调入仓库"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品名称:"
                    label-for="name"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="productName"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品69码:"
                    label-for="code"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="productCode"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                      cols="12"
                      md="4"
              >
                <xy-input-button label="调拨时间"
                                 type="time"
                                 v-on:change="fromChildren($event,['begin_time'])"
                                 :params="['begin_time']"
                                 :value="condition.begin_time"
                >
                </xy-input-button>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="调拨状态:"
                    label-for="status_id"
                    label-size="sm"
                >
                  <v-select
                      id="status_id"
                      :options="getCodeOptions('allot_status')"
                      :clearable="true"
                      v-model = "condition.statusId"
                      class="select-size-sm"
                      placeholder="选择调拨状态"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="责任人:"
                    label-for="duty_name"
                    label-size="sm"
                >
                  <b-form-input
                      id="duty_name"
                      size="sm"
                      v-model="dutyName"
                      @click="showSingleModal('责任人')"
                      readonly
                      placeholder="点击搜索责任人"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="调拨单编号:"
                    label-for="outbound_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="outboundNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <!--创建时间区间   -->
              <b-col
                      cols="12"
                      md="4"
              >
                <xy-input-button label="创建时间"
                                 type="time"
                                 v-on:change="fromChildren($event,['create_begin_time'])"
                                 :params="['create_begin_time']"
                                 :value="condition.create_begin_time"
                >
                </xy-input-button>
              </b-col>
              <!--运营审核-->
              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="运营审核:"
                        label-for="is_business_id"
                        label-size="sm"
                >
                  <v-select
                          id="is_business_id"
                          :options="getCodeOptions('yesno')"
                          :clearable="true"
                          v-model = "condition.is_business_id"
                          class="select-size-sm"
                          placeholder="选择审核状态"
                          :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>

              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="添加人:"
                        label-for="creatorId"
                        label-size="sm"
                >
                  <b-form-input
                          id="creatorId"
                          size="sm"
                          v-model="creatorName"
                          @click="showSingleModal('添加人')"
                          readonly
                          placeholder="点击搜索添加人"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="销售权:"
                    label-for="status"
                    label-size="sm"
                >
                  <v-select
                      :options="getCodeOptions('purchase_team')"
                      v-model="condition.purchaseTeamId"
                      name="status"
                      class="select-size-sm"
                      placeholder="请选择销售权"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="12"
                  class=" demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <!-- full detail on click -->
        <template #row-details="row">
          <!--<b-card v-for="i in row.item.ext.items" :key="i.name">-->
          <b-card>
            <div>
              <b-table striped hover :items="row.item.ext.item" :fields="fields">

                <template #cell(ext)="data">
                  {{ data.item.ext.code }}
                </template>

                <template #cell(purchaseTeam)="data">
                  {{ getCodeLabel('purchase_team', row.item.purchase_team_id) }}
                </template>

                <template #cell(name)="data">
                  [#{{ data.item.product_id }}]{{ data.item.name }}
                  <feather-icon
                      icon="CpuIcon"
                      size="21"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="success"
                      @click="function () {
                      stockOccupied.warehouse_id=row.item.fromwarehouse_id
                      stockOccupied.product_id=data.item.product_id
                      $refs['modal-stock-lock'].show()
                      $forceUpdate()
                      }"
                  />
                </template>

                <template #cell(warestatus)="data">
                  {{ data.item.warestatus === undefined ? '-' :  getCodeLabel('stock_status', data.item.warestatus) }}
                </template>

                <template #cell(remark)="data">
                  <div :id="`remark-${data.item.id}`">
                     <span v-if="data.item.remark">
                       {{ data.item.remark.substring(0, 6) }}
                     <span v-if="data.item.remark.length>6">...</span>
                     </span>
                  </div>
                  <b-tooltip :target="`remark-${data.item.id}`" placement="top">
                    {{ data.item.remark }}
                  </b-tooltip>
                </template>

                <template #cell(status)="data">
                  {{getCodeLabel('allot_outbound_status', data.item.status)}}
                </template>
                <template #cell(stock_year)="data">
                  {{data.item.stock_year}}-{{data.item.stock_month}}
                </template>


                <template #cell(trueqty)="data">
                  {{ data.item.trueqty }}
                  <feather-icon  icon="MoreVerticalIcon" @click="showBusinessTimeReceipt(row.item,data)" />
                </template>

                <template #cell(receiveqty)="data">
                  {{ data.item.receiveqty }}
                  <feather-icon  icon="MoreVerticalIcon" @click="showBusinessTimeReceiptReceive(row.item,data)" />
                </template>



              </b-table>
            </div>
          </b-card>
        </template>

        <!-- Columns -->
        <!-- Column: Type -->
        <template #cell(outbound_no)="data">
          [#{{ data.item.id }}]{{ data.item.outbound_no }}
        </template>

        <template #cell(select)="data" >
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                icon="MinusSquareIcon"
                size="15"
                class="align-middle text-body"
                v-show="data.detailsShowing==true"
            />
            <feather-icon
                icon="PlusSquareIcon"
                size="16"
                class="align-middle text-body"
                v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
        </template>

        <template #cell(fromwarehouse_name)="data">
          {{data.item.fromwarehouse_name}}
          <feather-icon
              icon="UserIcon"
              size="21"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              @click="showWarehouseCharge(data.item)"
              variant="success"
          />
          <!--          <br>-->
          <!--          {{data.item.from_contact}}  {{data.item.from_mobile}}-->
          <!--          <br>-->
          <!--          {{data.item.from_location}}-->
        </template>

        <template #cell(towarehouse_name)="data">
          {{data.item.towarehouse_name}}
          <feather-icon
              icon="UserIcon"
              size="21"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              @click="showToWarehouseCharge(data.item)"
              variant="success"
          />

          <!--          <br>-->
          <!--          {{data.item.to_contact}}  {{data.item.to_mobile}}-->
          <!--          <br>-->
          <!--          {{data.item.to_location}}-->
        </template>

        <template #cell(allot_time)="data">
          {{toDate(data.item.allot_time)}}
        </template>

        <template #cell(memo)="data">
          <attachment-upload :theme="'preview-dropdown'"
                             :attachment_id="'attachments'"
                             :id="data.item.attachments"
                             :object_type="'allot_outbound'"
                             :object_id="0"
                             :readonly="true"
                             @change=""
          />
          <!-- 备注 -->
          <div :id="`memo-row-${data.item.id}`">
            {{ data.value.substring(0, 3) }}
            <span v-if="data.value.length > 3"> ...</span>
          </div>
          <b-tooltip
              :target="`memo-row-${data.item.id}`"
              placement="top"
          >
            {{ data.value }}
          </b-tooltip>
        </template>

        <template #cell(allot_type)="data">
          {{getCodeLabel('allot_type', data.item.allot_type)}}
        </template>

        <template #cell(memo_ch)="data">
          <attachment-upload :theme="'preview-dropdown'"
                             :attachment_id="'attachments_ch'"
                             :id="data.item.attachments_ch"
                             :object_type="'allot_outbound'"
                             :object_id="0"
                             :readonly="true"
                             @change=""
          />
          <!-- 备注 -->
          <div :id="`memo_ch-row-${data.item.id}`">
            {{ data.value.substring(0, 3) }}
            <span v-if="data.value.length > 3"> ...</span>
          </div>
          <b-tooltip
              :target="`memo_ch-row-${data.item.id}`"
              placement="top"
          >
            {{ data.value }}
          </b-tooltip>
        </template>


        <!-- 附加费 -->
        <template #cell(extra_cost_subtotal)="data">
          <!-- <b-button variant="primary" v-b-modal.FeeModal >添加</b-button> -->
          <div :id="`extra_cost_subtotal-row-${data.item.id}`" >
            {{ data.value}}
            <span v-if="data.value > 1000000"> ...</span>
            <feather-icon icon="EditIcon" @click="showFee(data.item)"/>
          </div>
        </template>

        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('allot_status', data.item.status)}`"
          >
            {{ getCodeLabel("allot_status", data.item.status)}}
          </b-badge>
        </template>

        <template #cell(create_time)="data">
          {{toTime(data.item.create_time)}}
        </template>

        <template #cell(creator)="data">
          {{getCodeLabel('user', data.item.creator)}}
        </template>

        <template #cell(outbound_creator)="data">
          {{getCodeLabel('user', data.item.outbound_creator)}}
        </template>

        <template #cell(outbound_time)="data">
          {{toTime(data.item.outbound_time)}}
        </template>


        <template #cell(express_check_status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('yesno',data.item.express_check_status==1?1:0)}`"
          >
            {{ getCodeLabel('yesno', data.item.express_check_status==1?1:0) }}
          </b-badge>

        </template>

        <template #cell(is_finance)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('yesno',data.item.is_finance==1?1:0)}`"
          >

            {{ getCodeLabel('yesno', data.item.is_finance==1?1:0) }}
          </b-badge>
        </template>


        <template #cell(is_business)="data">
          <b-link
                  @click="changeState(data.item)"
          >
          <b-badge
                  pill
                  :variant="`light-${getCodeColor('yesno',data.item.is_business==1?1:0)}`"
          >

            {{ getCodeLabel('yesno', data.item.is_business==1?1:0) }}
          </b-badge>
          </b-link>
        </template>

        <template #cell(business_time_allotbound)="data" >
          {{ toTime(data.item.business_time_allotbound) }}
        </template>


        <template #cell(business_time_receipt)="data">
          {{ toTime(data.item.business_time_receipt) }}
        </template>






        <!--
                <template  #cell(attachments)="data">
                  <attachment-upload :theme="'preview-dropdown'"
                                     :attachment_id="'attachments'"
                                     :id="data.item.attachments"
                                     :object_type="'allot_outbound'"
                                     :object_id="0"
                                     :readonly="true"
                                     @change=""
                  />
                </template>
        -->


        <!--        <template  #cell(attachments_wh)="data">
                  <attachment-upload :theme="'preview-dropdown'"
                                     :attachment_id="'attachments_wh'"
                                     :id="data.item.attachments_wh"
                                     :object_type="'allot_outbound'"
                                     :object_id="0"
                                     :readonly="true"
                                     @change=""
                  />
                </template>-->

        <template #cell(memo_wh)="data">
          <attachment-upload :theme="'preview-dropdown'"
                             :attachment_id="'attachments_wh'"
                             :id="data.item.attachments_wh"
                             :object_type="'allot_outbound'"
                             :object_id="0"
                             :readonly="true"
                             @change=""
          />
          <!-- 备注 -->
          <div :id="`memo_wh-row-${data.item.id}`">
            {{ data.value.substring(0, 3) }}
            <span v-if="data.value.length > 3"> ...</span>
          </div>
          <b-tooltip
              :target="`memo_wh-row-${data.item.id}`"
              placement="top"
          >
            {{ data.value }}
          </b-tooltip>
        </template>

        <!-- Column: State -->
      <!--  <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-${getCodeColor('state', data.item.state)}`"

            >
              {{getCodeLabel('state', data.item.state)}}
            </b-badge>
          </b-link>
        </template>-->




        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >
            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>


            <div v-if="data.item.status == 0">
              <b-dropdown-item :to="{name:'apps-allotoutbound-edit',query:{id:data.item.outbound_id},params:{readonly:0}}">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">编辑调拨申请单</span>
              </b-dropdown-item>

              <b-dropdown-item @click="deleteState(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">删除</span>
              </b-dropdown-item>

              <div v-if="submitWareHouseLater">
                <b-dropdown-item @click="submitWareHouse(data.item.outbound_id)" >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">提交仓库</span>
                </b-dropdown-item>
              </div>
            </div>
            <b-dropdown-item :to="{name:'apps-allotoutbound-edit',query:{id:data.item.outbound_id},params:{readonly:1}}">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">浏览</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <!-- 附加费弹出窗口 -->
    <b-modal
        id="feeModal"
        ok-only
        ok-title="确认"
        cancel-title="取消"
        centered
        size="xl"
        title="附加费列表"
        ref="feeModal"
        @hidden="resetTable"
    >
      <fee-list
          ref="FeeList" :item="item">
      </fee-list>
    </b-modal>

    <b-modal
        id="modal-single"
        ok-only
        ok-title="确认"
        @ok="onSelectSingle"
        cancel-title="取消"
        centered
        size="lg"
        :title="'请选择'+modalName"
        ref="singleModal"
    >

      <!--  <company-select
                ref="companySelect" :companyType="companyType" v-if="modalName === '签约主体' || modalName === '供应商' "
        >
        </company-select>-->
      <WareHouse-Select
          ref="whSelect" v-if="modalName === '调出仓库' || modalName === '调入仓库' "
      >
      </WareHouse-Select>

      <!--:可加入 判断所属部门 userDepartment="userDepartment"-->
      <user-select
          ref="userSelect"  v-else-if="modalName === '责任人' || modalName === '添加人'"
      >
      </user-select>

     <!-- <user-select-more
              ref="userSelectMore"  v-else-if="modalName === '添加人'  "
      >
      </user-select-more>-->

    </b-modal>

    <b-modal
        id="modal-warehouse-charge"
        ok-only
        ok-title="确认"
        cancel-title="取消"
        centered
        size="lg"
        title="仓库负责人"
        ref="modal-warehouse-charge"
    >
      <warehouse-charge-list :warehouseId="itemWarehouseId" :warehouseName="itemWarehouseName" >

      </warehouse-charge-list>

    </b-modal>

    <!-- 调拨出入库明细   -->
    <b-modal
        id="businessTimeModalReceipt"
        size="lg"
        centered
        title="出入库明细"
        ref="businessTimeModalReceipt"
        hide-footer
    >
      <AllotInbound :id="outbound_id"  :no="outbound_no" :type="logType" :product_id="product_id"  @hide="hideBusinessTimeReceipt"></AllotInbound>
    </b-modal>

    <b-modal
        id="modal-stock-lock"
        ref="modal-stock-lock"
        no-close-on-backdrop
        cancel-title="取消"
        centered
        size="xl"
        title="锁库单据"
    >
      <stock-occupied-list
          :warehouse_id="stockOccupied.warehouse_id"
          :product_id="stockOccupied.product_id"
      >

      </stock-occupied-list>
    </b-modal>

    <div v-show="loadingModal"><xy-loading :placeholder="loadingPlaceholder"></xy-loading>  </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted, toRefs, reactive, onMounted} from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor,isEmpty } from '@core/utils/filter'
import allotoutboundUseList from './allotoutboundUseList'
import allotoutboundStore from './allotoutboundStore'
import { useToast } from 'vue-toastification/composition'
import FeeList from "@/views/apps/fee/FeeList";
import {useRouter} from "@core/utils/utils";
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload"
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import WareHouseSelect from '@/views/apps/warehouse/warehouse-modal/WarehouseList'
import WarehouseChargeList from "@/views/apps/warehousecharge/warehousecharge-modal/WarehouseChargeList";
import AllotInbound from '@/views/apps/allotoutbound/components/allotInbound'
import XyInputButton from "@/views/components/xy/XyInputButton";
import StockOccupiedList from "@/views/apps/stockoccupied/StockOccupiedList.vue";
import XyLoading from "@/views/components/xy/XyLoading";

export default {
  components: {
    StockOccupiedList,
    WarehouseChargeList,
    AllotInbound,
    UserSelect,
    WareHouseSelect,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    AttachmentUpload,
    FeeList,
    XyInputButton,
    XyLoading
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      stockOccupied:{},
      // 选中行的数据
      item: {},
      fields: [
        { key: 'ext', label: '商品69码'},
        { key: 'name', label: '名称'},
        { key: 'stock_year', label: '生产日期'},
        { key: 'purchaseTeam', label: '销售权'},
        { key: 'warestatus', label: '库存状态'},
        { key: 'qty', label: '计划调拨数量'},
        { key: 'remark', label: '备注'},
        { key: 'trueqty', label: '实际调拨库数量'},
        { key: 'receiveqty', label: '对方实收数量'},
        { key: 'status', label: '出库状态'},
      ],
      advanced_search_modal : false,
      modalName: '',
      fromwarehousename:'',
      towarehousename:'',
      dutyName: '',
      creatorName: '',
      itemWarehouseId:0,
      itemWarehouseName:"",
      outbound_no:'',
      outbound_id:'',
      logType:'',
      product_id:'',
      submitWareHouseLater:true,
      loadingModal:false,
      loadingPlaceholder:"单据正在生成,请勿重复操作!"

    }
  },
  methods: {
    changeState: function(data) {
      const is_business = 1 - data.is_business
      store.dispatch('allotoutbound/changeIsBusiness', {id: data.id, is_business: is_business}).then(res => {
        data.is_business = is_business
      })
    },
    showFee: function(item) {
      this.item = item
      this.item.fee_type_id = 4
      this.item.relational_id = item.outbound_id
      this.item.relational_no = item.outbound_no
      this.$refs["feeModal"].show()
    },
    resetTable: function() {
      this.$refs.refListTable.refresh()
    },

    showSingleModal(modalName) {
      this.modalName = modalName
      this.$refs['singleModal'].show()
    },




  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('allotoutbound')) store.registerModule('allotoutbound', allotoutboundStore)

    const router = useRouter()

    onMounted(() => {
      state.condition = store.getters['allotoutbound/getCondition']
    })
    const state = reactive({
      condition:{
      },
      curRow:undefined,
      rollbackModal:undefined,
      pdf_id:undefined,
      pdfModal:undefined,
    })

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('allotoutbound')) store.unregisterModule('allotoutbound')
    })

    const submitWareHouse = function (id){
      this.submitWareHouseLater = false
      if(confirm("确认执行此操作？")) {
        this.loadingModal = true
        store.dispatch('allotoutbound/submitWareHouse', {id: id}).then(res => {
          this.loadingModal = false
          this.submitWareHouseLater = true
          if (res.data.code == 0) {
            toast.success("操作成功")
            refetchData()
          } else {
            toast.error(res.data.data)
          }
        })
      }
      this.submitWareHouseLater = true
    }


    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        state.condition[modal[i]] =params==null?null:params[modal[i]]
      }
      this.$forceUpdate()
    }

    const showWarehouseCharge = function (params) {
      this.itemWarehouseId = params.fromwarehouse_id
      this.itemWarehouseName = params.fromwarehouse_name
      this.$refs['modal-warehouse-charge'].show()
    }
    const showToWarehouseCharge = function (params) {
      this.itemWarehouseId = params.towarehouse_id
      this.itemWarehouseName = params.towarehouse_name
      this.$refs['modal-warehouse-charge'].show()
    }
    const showBusinessTimeReceipt=function (item,data){

      this.outbound_no = item.outbound_no
      this.outbound_id = item.outbound_id
      this.product_id = data.item.product_id
      this.logType = '130'
      this.$refs['businessTimeModalReceipt'].show()
    }
    const showBusinessTimeReceiptReceive=function (item,data){

      this.outbound_no = item.outbound_no
      this.outbound_id = item.outbound_id
      this.product_id = data.item.product_id
      this.logType = '30'
      this.$refs['businessTimeModalReceipt'].show()
    }

    const hideBusinessTimeReceipt=function (){
      this.$refs['businessTimeModalReceipt'].hide()
      refetchData()
    }

    const methods = {
      searchByCondition(){
          this.refetchData()
      },
      onSelectSingle() {
        let data = {}
        switch (this.modalName) {
          case '责任人': {
            data = this.$refs.userSelect.getSelected()
            if(data.length > 0){
              this.dutyName = data[0].full_name;
              this.dutyId = data[0].user_id;
            }

            break
          }
          case '添加人': {
            data = this.$refs.userSelect.getSelected()
            if(data.length > 0){
              this.creatorName = data[0].full_name;
              state.condition.creatorId = data[0].user_id;
            }

            break
          }
          case '调出仓库': {
            data = this.$refs.whSelect.getSelected()
            if(data.length > 0){
              this.fromwarehousename = data[0].warehouse_name;
              this.fromwarehouseId = data[0].warehouse_id;
            }
            break
          }
          case '调入仓库':{
            data = this.$refs.whSelect.getSelected()
            if(data.length > 0){
              this.towarehousename = data[0].warehouse_name;
              this.towarehouseId = data[0].warehouse_id;
            }
            break;
          }



          default: {
            //statements;
            break
          }
        }
      },
      resetCondition() {
        this.fromwarehousename = ""
        this.fromwarehouseId = ""
        this.towarehousename = ""
        this.towarehouseId = ""
        this.productName = ""
        this.productCode = ""
        this.create_begin_time = ""
        this.begin_time = ""
        this.is_business_id = ""
        this.dutyName = ""
        this.dutyId = ""
        this.creatorName= ""
        this.outboundNo = ""

        for (let conditionKey in state.condition) {
          state.condition[conditionKey] = undefined
        }

        this.refetchData()
      }
    }

    const   deleteState= function (data) {
      if(confirm("确认执行此操作？")) {
        const state = 1 - data.state
        store.dispatch('allotoutbound/state', {
          id: data.id,
          state: state
        }).then(res => {
          console.log(res.data)
          if (res.data.code === 0) {
            toast.success('删除成功!')
            this.refetchData()
          } else {
            toast.error(res.data.data)
          }
        })
      }
    }



    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,

      towarehouseId,
      fromwarehouseId,
      productCode,
      productName,
      create_begin_time,
      begin_time,
      dutyId,

      outboundNo,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = allotoutboundUseList({
      outbound_id:router.route.value.query.outbound_id
    })

    return {
      searchList,
      tableColumns,
      ...toRefs(state),
      ...methods,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      towarehouseId,
      fromwarehouseId,
      productCode,
      productName,
      begin_time,
      create_begin_time,
      dutyId,
      outboundNo,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      showWarehouseCharge,
      showToWarehouseCharge,
      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toDate,
      toTime,
      submitWareHouse,
      advanced_search,
      showBusinessTimeReceipt,
      hideBusinessTimeReceipt,
      showBusinessTimeReceiptReceive,
      isEmpty,
      fromChildren,
      deleteState
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {isEmpty, second} from "@core/utils/filter";
export default function allotoutboundUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // {key: 'show_details', label: '详细内容', sortable: true},
    // { key: 'id', label: 'ID', sortable: true },
      { key: 'select', label: '#'},
    { key: 'outbound_no', label: '编号', sortable: true },
    { key: 'allot_type', label: '调拨类型', sortable: true },
    // { key: 'duty_id', label: '责任人ID user表', sortable: true },
      { key: 'duty_name', label: '责任人', sortable: true },
    // { key: 'fromwarehouse_id', label: '调出仓库ID', sortable: true },
    { key: 'fromwarehouse_name', label: '调出仓库', sortable: true },
    // { key: 'from_contact', label: '调出仓库联系人', sortable: true },
    // { key: 'from_mobile', label: '调出仓库联系电话', sortable: true },
    // { key: 'from_location', label: '调出仓库地址', sortable: true },
    // { key: 'towarehouse_id', label: '调入仓库ID', sortable: true },
    { key: 'towarehouse_name', label: '调入仓库', sortable: true },
    // { key: 'to_contact', label: '调入仓库联系人', sortable: true },
    // { key: 'to_mobile', label: '调入仓库联系电话', sortable: true },
    // { key: 'to_location', label: '调入仓库地址', sortable: true },
    { key: 'allot_time', label: '调拨日期', sortable: true },
    //{ key: 'extra_cost_subtotal', label: '附加费小计', sortable: true},
    { key: 'status', label: '调拨状态', sortable: true },
    // { key: 'state', label: '启用', sortable: true },
    //{ key: 'info', label: '详细信息', sortable: true },
    //{ key: 'attachments', label: '运营备注', sortable: true},
    { key: 'memo', label: '运营备注', sortable: true},
    //{ key: 'attachments_wh', label: '入库备注', sortable: true},
    { key: 'memo_wh', label: '入库备注', sortable: true},
    { key: 'memo_ch', label: '出库备注', sortable: true},
    // { key: 'update_time', label: '更新时间', sortable: true },
    // { key: 'updator', label: '更新人ID', sortable: true },
    { key: 'outbound_time', label: '出库时间', sortable: true },
    { key: 'outbound_creator', label: '出库人', sortable: true},
      { key: 'creator', label: '添加人', sortable: true },
      { key: 'create_time', label: '添加时间', sortable: true },
      { key: 'business_time_allotbound', label: '业务出库时间', sortable: true },
      { key: 'business_time_receipt', label: '业务入库时间', sortable: true },

    { key: 'actions', label: '操作'},
  ]


  if (store.state['allotoutbound'].isExpressStaff || store.state['allotoutbound'].isSuper){
    tableColumns.splice(tableColumns.length - 1,0,{ key: 'express_check_status', label: '物流审核', sortable: true })
  }

    if (store.state['allotoutbound'].isFinanceStaff || store.state['allotoutbound'].isSuper){
        tableColumns.splice(tableColumns.length - 1,0,{ key: 'is_finance', label: '财务审核', sortable: true })
    }

    if( store.state['allotoutbound'].isBusinessUser){
        tableColumns.splice(tableColumns.length - 1,0,{ key: 'is_business', label: '运营审核', sortable: true })
    }




  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const towarehouseId = ref('')
  const fromwarehouseId = ref('')
  const productCode = ref('')
  const productName = ref('')
  const begin_time = ref('')
  const outbound_begin_time= ref('')
  const outbound_end_time= ref('')
  const receipt_begin_time= ref('')
  const receipt_end_time= ref('')
  const create_begin_time = ref('')


  const dutyId = ref('')
  const outboundNo = ref('')
  const orderBy = ref('status asc , create_time ')
  const isSortDirDesc = ref(true)
  const express_check_status = ref('')
  const is_finance = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey ], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
      const condition = {...store.getters['allotoutbound/getCondition']}
      const params = {
          search_key: searchKey.value,
          productName: productName.value,
          productCode : productCode.value,
          fromwarehouseId: fromwarehouseId.value,
          towarehouseId: towarehouseId.value,
          dutyId: dutyId.value,
          outboundNo : outboundNo.value,
          start: start.value,
          limit: limit.value,
          order_by: orderBy.value.length === 0 ? "outbound_no" : orderBy.value ,
          order_desc: isSortDirDesc.value === true ? 'desc':'',
          outbound_id:config.outbound_id,
          outbound_begin_time :second(outbound_begin_time.value),
          outbound_end_time :second(outbound_end_time.value),
          express_check_status: express_check_status != null ? express_check_status.value.value : "",
          is_finance: is_finance != null ? is_finance.value.value : "",
      }
      Object.assign(params,condition)
      params.purchaseTeamId = isEmpty(condition.purchaseTeamId)?null:condition.purchaseTeamId.value
    store
      .dispatch('allotoutbound/search', params)
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }


  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    second,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    productName,
    productCode,
    begin_time,
    create_begin_time,
    towarehouseId,
    fromwarehouseId,
    dutyId,
    outboundNo,
    orderBy,
    isSortDirDesc,
    refListTable,
    express_check_status,
    outbound_begin_time,
    outbound_end_time,
      receipt_end_time,
      receipt_begin_time,
      is_finance,

    refetchData,

    // Extra Filters
  }
}

<template>
  <div>
    <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
    >

      <!-- Columns -->
      <template #cell(stock_no)="data">
        [#{{data.item.stock_id}}]{{data.item.stock_no}}
      </template>

      <template #cell(full_name)="data">
        {{getCodeLabel('warehouse_location', data.item.ext.fullName)}}
      </template>


      <template #cell(log_type)="data">
        {{getCodeLabel('stock_log_type', data.item.log_type)}}
      </template>

      <template #cell(log_qty_sign)="data">
        {{data.item.log_qty_sign}}
      </template>

      <template #cell(status)="data">
        <!--          <span :class="'text-'+getCode('stock_status', data.item.status).color">{{ getCodeLabel("stock_status", data.item.status)}}</span>-->
        <b-badge
            pill
            :variant="`light-${getCodeColor('stock_status', data.item.status)}`"
        >
          {{ getCodeLabel("stock_status", data.item.status)}}
        </b-badge>
      </template>



      <template #cell(add_time)="data">
        {{toTime(data.item.add_time)}}
      </template>

      <template #cell(creator)="data">
        {{getCodeLabel('user', data.item.creator)}}
      </template>

      <!-- Column: State -->
      <template #cell(state)="data">
        <b-link
            @click="changeState(data.item)"
        >
          <b-badge
              pill
              :variant="`light-$ {getCodeColor('state', data.item.state)}`"

          >
            {{getCodeLabel('state', data.item.state)}}
          </b-badge>
        </b-link>
      </template>



    </b-table>

  </div>
</template>

<script>
import XyDatePicker from '@/views/components/xy/XyDatePicker'
import { useToast } from 'vue-toastification/composition'
import store from '@/store'
import purchaseinboundStore from '@/views/apps/purchaseinbound/purchaseinboundStore'
import { useRouter } from '@core/utils/utils'
import { onMounted, onUnmounted, reactive, toRefs } from '@vue/composition-api'
import { second } from '@core/utils/dayjs/utils'
import allotoutboundStore from "@/views/apps/allotoutbound/allotoutboundStore";
import stocklogUseList from "@/views/apps/allotoutbound/components/stocklogUseList";
import {avatarText, getCode, getCodeColor, getCodeLabel, getCodeOptions, toDate, toTime} from "@core/utils/filter";
import stocklogStore from "@/views/apps/stocklog/stocklogStore";

export default {
  components: { XyDatePicker },
  props: ['id','no','type','product_id'],
  setup(props,{emit}) {
    const toast = useToast()
    // Register module
    if (!store.hasModule('allotoutbound')) store.registerModule('allotoutbound', allotoutboundStore)
    if (!store.hasModule('stocklog')) store.registerModule('stocklog', stocklogStore)

    onUnmounted(() => {
      if (store.hasModule('stocklog')) store.unregisterModule('stocklog')
    })

    const state = reactive({
      business_time: undefined,
      observer: undefined,
    })

    const methods = {
      async save() {
        const validate = await state.observer.validate()
        if (!validate) {
          toast.error('请正确填写必填字段！')
          return
        }
        store.dispatch('allotoutbound/updateModelReceipt', {
          inbound_id: props.id,
          business_time: second(state.business_time)
        })
            .then(res => {
              if (res.data.code === 0) {
                toast.success(res.data.data)
                emit('hide')
              } else {
                toast.error(res.data.data)
              }
            })
      }
    }
    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = stocklogUseList({
      relationalId:props.id,
      relationalNo:props.no,
      type:props.type,
      product_id:props.product_id,
    })

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      toTime, toDate,
      // UI
      ...toRefs(state),
      ...methods,
    }

  }
}
</script>

<style scoped>

</style>
